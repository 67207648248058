import React from 'react';
import { useEffect, useState } from 'react';
import S from '../../styled';
import Page from '../../components/Page';

export default function ChatPage({ store: { currentUser, account, t } }) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const isCometChatWidgetLoaded = () => {
      return typeof window.CometChatWidget !== 'undefined';
    };

    const loadScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://widget-js.cometchat.io/v3/cometchatwidget.js";
        script.async = true;

        script.onload = resolve;
        script.onerror = reject;

        document.body.appendChild(script);
      });
    };

      const initCometChatWidget = async (account) => {
        try {
          if (!isCometChatWidgetLoaded()) {
            await loadScript();
          }

          const { comet_chat_enabled, comet_chat_app_id, comet_chat_widget_id } = account;
          const { comet_chat_auth_token } = currentUser;

          if(!comet_chat_enabled) return;

          if (!comet_chat_app_id || !comet_chat_widget_id || !comet_chat_auth_token) {
              console.error("Error: The account is missing required CometChat configuration");
              return;
          }

          if (window.CometChatWidget && !initialized) {
            window.CometChatWidget.init({
              appID: comet_chat_app_id,
              appRegion: 'US',
            }).then(() => {
              window.CometChatWidget.login({
                authToken: comet_chat_auth_token
              }).then(() => {
                window.CometChatWidget.launch({
                  widgetID: comet_chat_widget_id,
                  docked: false,
                  roundedCorners: false,
                  height: '80vh',
                  width: '100%',
                  defaultType: 'user',
                  target: '.cometchat-screen'
                });
              });
            });
            setInitialized(true);
          }
        } catch (error) {
          console.error("Error loading the CometChatWidget script:", error);
        }
      };

      if (currentUser && account) {
        initCometChatWidget(account);
      }
  }, [currentUser, account]);

  return (
    <Page title={t('navigation.chat')} alignStart justifyStart>
      <S.Flex column>
        <div
          className="cometchat-screen"
          style={{
            width: '100%',
            height: '100%',
            maxHeight: '200px',
            margin: '0 auto',
            border: '0px solid #ccc',
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            zIndex: 1
          }}
        ></div>
      </S.Flex>
    </Page>
  );
}
