import React from 'react';

// Styles
import S from '../../styled';

// Intercom
import { useIntercom } from 'react-use-intercom';

// Routes
import { routeNames } from '../../routing/routes';

// Helpers
import { useFeatureFlag } from '../../helpers/useFeatureFlag';
import navigateTo from '../../helpers/navigateTo';
import {
  getDelegateRelationshipIdFromLocalStorage,
  removeDelegateRelationshipIdFromLocalStorage,
} from '../../helpers/delegates';
import {
  PORTAL_AFFILIATE_DISABLED,
  PORTAL_PAYMENTS_DISABLED,
  PORTAL_CAMPAIGN_SUMMARY
} from '../../helpers/constants';

// Icons
import {
  User as UserIcon,
  DollarSign as DollarIcon,
  Receipt as ReceiptIcon,
  Headset as HeadsetIcon,
  Comment as CommentIcon,
  SignOutAlt as SignOutIcon,
  PeopleArrows as PeopleArrowsIcon,
  Bullhorn as BullhornIcon,
} from '@styled-icons/fa-solid';
import { Settings as SettingsIcon } from '@styled-icons/material';

// Network logout
import { useAuthentication } from '../../authentication';

export default function NavigationContent(props) {
  const { store, setStore, history, desktopMode } = props;
  const { token, account, currentUser, t } = store;
  const { setRenderNavigation, setCurrentUser } = setStore;
  const { logout } = useAuthentication();


  const closeNavigation = () => setRenderNavigation(false);

  const navigateToPage = (pageName) => {
    navigateTo(history, pageName, token);
    closeNavigation();
  };

  const navigateToDashboard = () => navigateToPage(routeNames.dashboard);
  const navigateToCampaigns = () => navigateToPage(routeNames.campaigns);
  const navigateToPayments = () => navigateToPage(routeNames.payments);
  const navigateToChat = () => navigateToPage(routeNames.chat);
  const navigateToPerformance = () => navigateToPage(routeNames.performance);
  const navigateToSettings = () => navigateToPage(routeNames.settings);

  const isSignedInAsDelegate = () => account?.delegates_enabled && typeof getDelegateRelationshipIdFromLocalStorage() === 'string';
  const navigateToManagedCreators = () => {
    if (!account.delegates_enabled) return;
    removeDelegateRelationshipIdFromLocalStorage();
    setCurrentUser({
      ...currentUser,
      delegate_relationship_id: null,
      acting_as_delete: true
    });
    navigateToPage(routeNames.delegates);
  };

  const { show: showIntercom } = useIntercom();

  const signOutUser = async () => {
    try {
      await logout();
    } finally {
      navigateToPage(routeNames.login);
      closeNavigation();
    }
  };

  const selectedPath = (path) => {
    const location = history.location?.pathname || '';
    const splitLocation = location.split('/');
    return splitLocation[2] === path;
  };

  const portalAffiliateDisabled = useFeatureFlag(
    store,
    PORTAL_AFFILIATE_DISABLED
  );

  const portalPaymentsDisabled = useFeatureFlag(
    store,
    PORTAL_PAYMENTS_DISABLED
  );

  const portalCampaignSummary = useFeatureFlag(
    store,
    PORTAL_CAMPAIGN_SUMMARY
  );

  const commissionsEnabled = account?.commissions_enabled;
  const settingsEnabled = account?.settings_enabled;

  const renderNavItems = () => {
    const hideNavItems = props.routeSettings.hideNavItems;
    const isHideNavItemsBool = typeof hideNavItems === 'boolean';

    return isHideNavItemsBool ? !hideNavItems : true;
  };

  const displayPaymentInfo = () => {
    if (!currentUser || portalPaymentsDisabled || currentUser?.acting_as_portal_preview) return;

    const {
      delegate_relationship_payee_id,
      delegate_relationship_id
    } = currentUser;

    if (!delegate_relationship_payee_id) return true;
    return delegate_relationship_payee_id == delegate_relationship_id;
  };

  const displayChat = () => {
    if (!currentUser) return false;
    if (!account) return false;

    const { comet_chat_auth_token } = currentUser;
    const hasCometChatAuthToken = Boolean(comet_chat_auth_token && comet_chat_auth_token.trim() !== '');

    return hasCometChatAuthToken && account.comet_chat_enabled;
  }

  const renderableNavigationLinks = [
    {
      path: undefined,
      icon: UserIcon,
      onClick: navigateToDashboard,
      text: t('navigation.dashboard'),
      renderCondition: renderNavItems(),
    },
    {
      path: 'campaigns',
      icon: BullhornIcon,
      onClick: navigateToCampaigns,
      text: t('navigation.campaigns'),
      renderCondition: renderNavItems() && portalCampaignSummary,
    },
    {
      path: 'payments',
      icon: DollarIcon,
      onClick: navigateToPayments,
      text: t('navigation.payments'),
      renderCondition: renderNavItems() && displayPaymentInfo(),
    },
    {
      path: 'chat',
      icon: CommentIcon,
      onClick: navigateToChat,
      text: t('navigation.chat'),
      renderCondition: renderNavItems() && displayChat(),
    },
    {
      path: 'performance',
      icon: ReceiptIcon,
      onClick: navigateToPerformance,
      text: t('navigation.performance'),
      renderCondition:
        renderNavItems() && commissionsEnabled && !portalAffiliateDisabled,
    },
    {
      path: 'settings',
      icon: SettingsIcon,
      onClick: navigateToSettings,
      text: t('navigation.settings'),
      renderCondition: renderNavItems() && settingsEnabled,
    },
    {
      path: null,
      icon: HeadsetIcon,
      onClick: showIntercom,
      text: t('navigation.contactSupport'),
      renderCondition: renderNavItems(),
    },
    {
      path: 'managed-creators',
      icon: PeopleArrowsIcon,
      onClick: navigateToManagedCreators,
      text: t('navigation.managedCreators'),
      renderCondition: isSignedInAsDelegate(),
    },
    {
      path: null,
      icon: SignOutIcon,
      onClick: signOutUser,
      text: t('navigation.signout'),
      renderCondition: true,
    },
  ];

  return (
    <>
      <S.Flex column width="100%" flex={1}>
        {renderableNavigationLinks.map(
          ({ path, icon: Icon, onClick, text, renderCondition }, index) =>
            renderCondition && (
              <S.Link key={index} navigation onClick={onClick}>
                <S.Flex>
                  {!desktopMode && (
                    <S.SvgWrapper height={25} width={25} marginRight={10}>
                      <Icon />
                    </S.SvgWrapper>
                  )}
                  <S.Text
                    boldWeight={selectedPath(path)}
                    lightWeight={!selectedPath(path)}
                  >
                    {text}
                  </S.Text>
                </S.Flex>
              </S.Link>
            )
        )}
      </S.Flex>
    </>
  );
}
