import axios from 'axios';

// Config
import config from '../config';

// Helpers
import { DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME } from '../helpers/constants';

const axiosInstance = axios.create({
	baseURL: config.baseApiUrl,
	headers: {
		'Content-Type': 'application/vnd.api+json',
	},
	withCredentials: true,
});

axiosInstance.interceptors.request.use((request) => {
	// NOTE: This occurs when a User intends to sign in as a delegate and selects a relationship
	//       to act as another portal user
	const delegateRelationshipId = localStorage.getItem(DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME);
	if (delegateRelationshipId && !request.skipDelegateHeader) {
		request.headers['X-Delegate-Relationship-Id'] = delegateRelationshipId;
	}
	return request;
}, function (error) {
	// NOTE: Do something with request error
	return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
    // NOTE: Any status code that lie within the range of 2xx cause this function to trigger; do something with response data
    return response;
  }, (error) => {
    // NOTE: Any status codes that falls outside the range of 2xx cause this function to trigger; do something with response error

		// Note: Axios doesn't handle 302s. To get around this we're redirecting
		//       with a 403 error and the location in the response
		const redirectLocation = error?.response?.data?.location;
		if (redirectLocation) {
			window.location = redirectLocation;
		} else {
			return Promise.reject(error);
		}
});

const api = (params) => axiosInstance(params).then((response) => {
	let { data } = response;
	if (data) {
		if ('data' in data) data = data.data;

		if (Array.isArray(data)) {
			data = data.map(d => ({ id: d.id, ...d.attributes }));
		} else if (data && data.attributes) {
			data = { id: data.id, ...data.attributes };
		}
	}
	return data;
});

export default api;
