import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import S from '../../styled';


// Routes
import { routeNames } from '../../routing/routes';

// Components
import Module from '../Module';
import Copyable from '../Copyable';
import Metric from '../Metric';
import QRCodeButton from '../QRCodeButton';

// Requests
import { getPartnership } from '../../data/requests/partnership';

// Helpers
import navigateTo from '../../helpers/navigateTo';
import { useFeatureFlag } from '../../helpers/useFeatureFlag';

export default function PartnershipModule(props) {
  const history = useHistory();

  const { store, fullDisplay, flex } = props;
  const { token, currentProfile, t } = store;

  const [isMounted, setIsMounted] = useState(true);
  const [moduleData, setModuleData] = useState({});
  const [moduleIsLoading, setModuleIsLoading] = useState(true);

  const isTrackingLinkQRCodeEnabled = useFeatureFlag(store, 'portal_tracking_link_qr_code');

  const loadModuleData = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const data = await getPartnership(token);
      setModuleData(data);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  const navigateToTerms = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.terms, token);
  };

  const navigateToProductLink = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.productLink, token);
  };

  const navigateToPrivacyPolicy = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.privacyPolicy, token);
  };

  const perSale = moduleData.per_sale;
  const discount = moduleData.discount;
  const cookieDays = moduleData.cookie_days;
  const couponCode = moduleData.coupon_code;
  const conversionLink = (moduleData.conversion_links || {}).default_link;
  const renderTermsLink = moduleData.has_terms;
  const renderPrivacyPolicyLink = moduleData.has_privacy_policy;
  const productLinkable = moduleData.product_linkable;

  const isRenderable = couponCode || conversionLink;

  return (
    <Module
      title={t('navigation.partnership')}
      flex={flex}
      isLoading={moduleIsLoading}
      isRenderable={isRenderable}
    >
      <S.Flex column justifyStart overflowHidden>
        <S.Visibility desktop>
          <S.Flex
            row={!fullDisplay}
            column={fullDisplay}
            flex={1}
            gap={24}
            marginBottom={24}
          >
            {perSale && <Metric smallSize name={t('components.PartnershipModule.perSale')} value={perSale} />}
            {cookieDays && (
              <Metric smallSize name={t('components.PartnershipModule.cookieDays')} value={cookieDays} />
            )}
            {discount && <Metric smallSize name={t('components.PartnershipModule.discount')} value={discount} />}
          </S.Flex>
        </S.Visibility>
        <S.Flex column flex={1}>
          <S.Visibility mobile tablet width="100%">
            <S.Flex row flex={1} gap={24} marginBottom={24}>
              {perSale && <Metric smallSize name={t('components.PartnershipModule.perSale')} value={perSale} />}
              {cookieDays && (
                <Metric smallSize name={t('components.PartnershipModule.cookieDays')} value={cookieDays} />
              )}
              {discount && (
                <Metric smallSize name={t('components.PartnershipModule.discount')} value={discount} />
              )}
            </S.Flex>
          </S.Visibility>

          <S.Flex column alignStart gap={14} width="75%">
            {couponCode && <Copyable name={t('components.PartnershipModule.yourCode')} value={couponCode} store={store} />}

            {conversionLink && (
              isTrackingLinkQRCodeEnabled ? (
                <>
                  <S.Visibility tablet desktop>
                    <S.Flex gap={10} alignEnd>
                      <Copyable name={t('components.PartnershipModule.yourLink')} value={conversionLink} store={store} />
                      <QRCodeButton width={100} height={42} url={conversionLink} name={currentProfile?.name} store={store} />
                    </S.Flex>
                  </S.Visibility>

                  <S.Visibility mobile>
                    <S.Flex column gap={10}>
                      <Copyable name={t('components.PartnershipModule.yourLink')} value={conversionLink} store={store} />
                      <QRCodeButton height={42} url={conversionLink} name={currentProfile?.name} store={store} />
                    </S.Flex>
                  </S.Visibility>
                </>

              ) : (
                <Copyable name={t('components.PartnershipModule.yourLink')} value={conversionLink} store={store} />
              )
            )}
          </S.Flex>

          {productLinkable && (
            <S.Flex row justifyCenter marginTop={24}>
              <S.Button outline smallSize onClick={navigateToProductLink}>
                {t('components.PartnershipModule.shareSpecificProduct')}
              </S.Button>
            </S.Flex>
          )}
          <S.Flex row justifyEvenly alignCenter marginTop={24}>
            {renderTermsLink && (
              <S.Flex flex={1}>
                <S.Link href="#" onClick={navigateToTerms}>
                  <S.Text>{t('components.PartnershipModule.terms')}</S.Text>
                </S.Link>
              </S.Flex>
            )}
            {renderPrivacyPolicyLink && (
              <S.Flex flex={1}>
                <S.Link href="#" onClick={navigateToPrivacyPolicy}>
                  <S.Text>{t('components.PartnershipModule.privacyPolicy')}</S.Text>
                </S.Link>
              </S.Flex>
            )}
          </S.Flex>
        </S.Flex>
      </S.Flex>
    </Module>
  );
}
