import React from 'react';
import config from './config';
import { ThemeProvider } from 'styled-components';
import { withProviders } from './helpers/withProvider';
import { AuthenticationProvider } from './authentication';
import './App.css';

// Routes
import Router from './routing/Router';
import routes from './routing/routes';

// store
import Store from './Store';

// Headers
import Head from './Head';

// Styles
import { getTheme } from './styled/Themes';
import GlobalStyles from './styled/Global';

// Intercom
import { IntercomProvider } from 'react-use-intercom';

const App = () => {
  return (
    <Store render={({ store, setStore }) => (
      <AuthenticationProvider store={store} setStore={setStore}>
        <ThemeProvider theme={getTheme(store.theme)}>
          <>
            <Head store={store} />
            <GlobalStyles />
            <Router routes={routes} store={store} setStore={setStore} />
          </>
        </ThemeProvider>
      </AuthenticationProvider>
    )} />
  )
};

export default withProviders(
  [IntercomProvider, { autoBoot: true, appId: config?.intercomAppId}],
)(App);
